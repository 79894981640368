import { useAuthStore } from "@store/storeAuth";
import { useSessionStore } from "@store/storeSession";
import { useContractsStore } from "@store/storeContratti";
import { useNavigate } from "react-router-dom";
import ROUTES from "@routes/constants";
import useApiHoc from "./useApiHoc";

const useGoToApp = () => {
  const { fetchUser } = useAuthStore();
  const { deleteSavedIdAndToken, resetSession } = useSessionStore();
  // const { resetHome } = useContractsStore();
  const navigate = useNavigate();
  const apiHoc = useApiHoc();

  return async (toPending = false) => {
    localStorage.removeItem("isOnboarding");
    localStorage.removeItem("isSingleFile");
    const id = localStorage.getItem("idToFetchSavedSessionTokenAndId");

    resetSession();
    if (id) await apiHoc(() => deleteSavedIdAndToken(id));
    await apiHoc(() => fetchUser());

    if (toPending) {
      navigate(ROUTES.PENDING);
    } else {
      navigate(ROUTES.HOME);
    }
  };
};

export default useGoToApp;
